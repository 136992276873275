<div class="about-page-wrapper max-w-none prose lg:prose-lg px-5" >

  <cms-content-status [status]="status"></cms-content-status>

  <div class="container mx-auto  max-w-6xl" >

    <div class="-mt-10 -mb-4 border-b border-gray-200">
      <h2>{{ title }}</h2>
    </div>

    <div class="flex flex-wrap overflow-hidden">

      <div class="flex flex-col items-center justify-center w-full overflow-hidden">
        <div class="content lg:prose-lg" *ngIf="markdownContent" >

          <markdown [data]="markdownContent" (ready)="onReadyMarkdownContent()"></markdown>

          <div class="flex flex-wrap overflow-hidden -mx-2">

            <div class="w-full overflow-hidden md:w-1/2 px-2">
              <markdown [data]="markdownContentArea" (ready)="onReadyMarkdownContent()"></markdown>
            </div>

            <div class="w-full overflow-hidden md:w-1/2 px-2">
              <markdown [data]="markdownContentNumbers" (ready)="onReadyMarkdownContent()"></markdown>
            </div>

          </div>

        </div>
      </div>

    </div>

  </div>

</div>

