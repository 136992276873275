<div class="media-page-wrapper max-w-none prose lg:prose-lg px-5" >

  <!-- <cms-content-status [status]="status"></cms-content-status> -->

  <!-- <ng-container *ngIf="!gallery" >
    Učitavanje...
  </ng-container> -->

  <ng-container>

    <div class="container mx-auto">
      <div class="-mt-10 mb-5 border-b border-gray-200">
        <h2>Mediji</h2>
      </div>
    </div>

    <div class="container mx-auto flex flex-col items-center" >

      <div class="mt-8 links">

        <!-- <div class="link bg-white rounded-xl shadow-lg flex flex-col items-start justify-center w-full p-8">
          <div><b>Knjiga grafičkih standarda projekta</b></div>
          <div>preuzimanje knjige grafičkih standarda</div>
          <div>
            <a href="https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FZamjena%20spojnog%20vrelovoda%20od%20TE-TO%20Osijek%20do%20TO%20Osijek%20-%20Knjiga%20grafickih%20standarda.pdf?alt=media&token=0c6daecf-913c-48ac-a6d3-1a3d3e59b0e8" target="_blank" class="text-primary-red no-underline">Preuzimanje &rarr;</a>
          </div>
        </div> -->

        <div class="link bg-white rounded-xl shadow-lg flex flex-col items-start justify-center w-full p-8">
          <div><b>Logotip projekta i HEP Toplinarstva</b></div>
          <div>preuzimanje logotipa projekta</div>

          <div *ngIf="isLogosVisible" class="mb-4">
            <div class="flex flex-wrap overflow-hidden">

              <div  *ngFor="let logo of logos"
                    class="w-full overflow-hidden sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/4">
                <!-- {{ logo | json }} -->


                <img [src]="logo.svg" class="w-full min-w-full" style="margin-bottom: 0;" [alt]="logo.title" >

                <div class="text-sm font-bold px-2 text-center" *ngIf="logo.title">
                  {{ logo.title }}
                </div>
                <div class="text-center">
                  <a *ngIf="logo.svg" [href]="logo.svg" target="_blank" >
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-primary-blue uppercase last:mr-0 mr-1">
                      SVG
                    </span>
                  </a>

                  <a *ngIf="logo.png" [href]="logo.png" target="_blank" >
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-primary-blue uppercase last:mr-0 mr-1">
                      PNG
                    </span>
                  </a>

                  <a *ngIf="logo.pdf" [href]="logo.pdf" target="_blank" >
                    <span class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-primary-red uppercase last:mr-0 mr-1">
                      PDF
                    </span>
                  </a>
                </div>

              </div>

            </div>
          </div>

          <div>
            <a (click)="isLogosVisible = !isLogosVisible" class="text-primary-red no-underline cursor-pointer">
              <ng-container *ngIf="!isLogosVisible">
                Preuzmite &rarr;
              </ng-container>

              <ng-container *ngIf="isLogosVisible">
                &larr; Sakrijte
              </ng-container>
            </a>
            <!-- <br> -->
            <!-- <a href="https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FZamjena%20spojnog%20vrelovoda%20od%20TE-TO%20Osijek%20do%20TO%20Osijek%20-%20logotip.zip?alt=media&token=188f6c25-fa03-4581-b622-0c3759c43959" target="_blank" class="text-primary-red no-underline">Preuzimanje &rarr;</a> -->
          </div>
        </div>

        <div class="link bg-white rounded-xl shadow-lg flex flex-col items-start justify-center w-full p-8">
          <div><b>Priopćenja za medije</b></div>
          <div>preuzmite razne dokumente za medije</div>

          <div *ngIf="isForMediaVisible" class="mb-4 w-full">
            <div class="flex flex-wrap overflow-hidden">

              <div  *ngFor="let forMediaItem of forMedia"
                    class="w-full overflow-hidden border border-gray-200 rounded-lg p-4 mt-4">

                <ng-container *ngIf="forMediaItem.internal" >
                  <a [routerLink]="forMediaItem.internal" >
                    <div class="text-sm font-bold px-2" >
                      {{ forMediaItem.title }}
                    </div>
                  </a>
                </ng-container>

                <ng-container *ngIf="forMediaItem.external" >
                  <a [href]="forMediaItem.external" target="_blank" >
                    <div class="text-sm font-bold px-2" >
                      {{ forMediaItem.title }}
                    </div>
                  </a>
                </ng-container>

              </div>

            </div>
          </div>

          <div>
            <a (click)="isForMediaVisible = !isForMediaVisible" class="text-primary-red no-underline cursor-pointer">
              <ng-container *ngIf="!isForMediaVisible">
                Preuzmite &rarr;
              </ng-container>

              <ng-container *ngIf="isForMediaVisible">
                &larr; Sakrijte
              </ng-container>
            </a>
          </div>
        </div>

        <!-- <div class="link bg-white rounded-xl shadow-lg flex flex-col items-start justify-center w-full p-8">
          <div><b>Knjiga grafičkih standarda HEP Toplinarstva</b></div>
          <div>preuzimanje knjige grafičkih standarda</div>
          <div>
            <a href="https://www.hep.hr/UserDocsImages/slike/logotipovi-mediji/pdf/HEP-Toplinarstvo_knjiga_standarda.pdf" target="_blank" class="text-primary-red no-underline">Preuzimanje &rarr;</a>
          </div>
        </div> -->

        <!-- <div class="link bg-white rounded-xl shadow-lg flex flex-col items-start justify-center w-full p-8">
          <div><b>Brošura projekta</b></div>
          <div>preuzimanje brošure projekta</div>
          <div>
            <a href="https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FZamjena%20spojnog%20vrelovoda%20od%20TE-TO%20Osijek%20do%20TO%20Osijek%20-%20Brosura.pdf?alt=media&token=da16851d-8651-4f24-9b7f-3763d3f49381" target="_blank" class="text-primary-red no-underline">Preuzimanje &rarr;</a>
          </div>
        </div> -->

        <!-- <div class="link bg-white rounded-xl shadow-lg flex flex-col items-start justify-center w-full p-8">
          <div><b>Roll up</b></div>
          <div>preuzimanje materijala za tisak</div>
          <div>
            <a href="https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FZamjena%20spojnog%20vrelovoda%20od%20TE-TO%20Osijek%20do%20TO%20Osijek%20-%20Roll%20up%20100x200cm.jpg?alt=media&token=3892a495-375d-4186-9228-eb6c60d2084c" target="_blank" class="text-primary-red no-underline">Preuzimanje &rarr;</a>
          </div>
        </div> -->

        <div class="link bg-white rounded-xl shadow-lg flex flex-col items-start justify-center w-full p-8">
          <div><b>Galerija fotografija projekta</b></div>
          <div>preuzimanje izabranih fotografija za objave</div>
          <div>
            <a href="https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2Fgalerija-do-2021-04-21.zip?alt=media&token=b7abc971-b456-496e-89ac-6641a5d28053" target="_blank" class="text-primary-red no-underline">Preuzimanje &rarr;</a>
          </div>
        </div>

        <div class="link bg-white rounded-xl shadow-lg flex flex-col items-start justify-center w-full p-8">
          <div><b>Publikacije</b></div>
          <div>pogledajte sve publikacije vezane za projekt</div>

          <div *ngIf="isPublicationsVisible" class="mb-4 w-full">
            <div class="flex flex-wrap overflow-hidden">

              <div  *ngFor="let publication of publications"
                    class="w-full overflow-hidden border border-gray-200 rounded-lg p-4 mt-4">

                <ng-container *ngIf="publication.internal" >
                  <a [routerLink]="publication.internal" >
                    <div class="text-sm font-bold px-2" >
                      {{ publication.title }}
                    </div>
                  </a>
                </ng-container>

                <ng-container *ngIf="publication.external" >
                  <a [href]="publication.external" target="_blank" >
                    <div class="text-sm font-bold px-2" >
                      {{ publication.title }}
                    </div>
                  </a>
                </ng-container>

              </div>

            </div>
          </div>

          <div>
            <a (click)="isPublicationsVisible = !isPublicationsVisible" class="text-primary-red no-underline cursor-pointer">
              <ng-container *ngIf="!isPublicationsVisible">
                Preuzmite &rarr;
              </ng-container>

              <ng-container *ngIf="isPublicationsVisible">
                &larr; Sakrijte
              </ng-container>
            </a>
          </div>
        </div>

      </div>

    </div>

  </ng-container>
</div>
