import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'sss-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {

  @Input()
  navbarItems: { title: string, link: string }[];

  public navbar = {
    // titleImage: 'https://ik.imagekit.io/vrelovodosijek/hep-icon.svg',
    // titleImage: 'https://ik.imagekit.io/vrelovodosijek/hep-toplinarstvo-logo-transparent.svg',
    titleImage: 'https://ik.imagekit.io/vrelovodosijek/vrelovod-osijek-icon.svg',
    title: `Toplina za budućnost`,
    titleLink: '/',
    activeLink: '/',
    // mainImage: 'https://ik.imagekit.io/vrelovodosijek/tr:h-256,q-100/hep-toplinarstvo-logo-transparent.png',
    // mainImage: 'https://ik.imagekit.io/vrelovodosijek/tr:h-512,q-100/eu-fondovi-vidljivost-clean.png',
    mainImage: 'https://ik.imagekit.io/vrelovodosijek/eu-fondovi-vidljivost-clean-transparent.svg',
    items: [],
  }

  constructor() {

  }

  ngOnInit(): void {
    this.navbar.items = this.navbarItems;
  }

}
