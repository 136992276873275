<div class="footer-wrapper bg-gray-50 border-t border-b">

  <footer class="flex flex-col items-center justify-content text-center p-8 pt-16 pb-24">
    <div class="font-semibold">
      <!-- Sadržaj mrežne stranice isključiva je odgovornost HEP-TOPLINARSTVA d.o.o.<br> -->
      Projekt je sufinancirala Europska unija iz Europskog fonda za regionalni razvoj
    </div>

    <div class="mt-4">
      Izradu internetske stranice sufinancirala je Europska unĳa iz Europskog fonda za regionalni razvoj
    </div>

    <div class="flex items-center justify-content">
      <!-- <img src="https://ik.imagekit.io/vrelovodosijek/eu-flag.svg" alt="" class="w-20 h-15 m-8 mt-4"> -->
      <a href="https://strukturnifondovi.hr" target="_blank" >
        <!-- <img src="https://ik.imagekit.io/vrelovodosijek/tr:h-512,q-100/eu-fondovi-vidljivost-with-frame.png" class="max-h-24 mt-0 max-w-full" > -->
        <img src="https://ik.imagekit.io/vrelovodosijek/eu-fondovi-vidljivost-clean-transparent.svg" class="max-h-14 mt-4 max-w-full" >
      </a>
    </div>
    <div class="w-full lg:w-center text-center font-semibold mt-4">
      Sva prava pridržana © Copyright 2021.<br>
      <a href="https://www.hep.hr/toplinarstvo/" target="_blank" >
        <img src="https://ik.imagekit.io/vrelovodosijek/hep-toplinarstvo-logo-with-business-type.svg" class="max-h-8 mt-4 max-w-full inline-block" >
      </a>
    </div>
    <div class="font-light text-xs mt-4">
      Odricanje od odgovornosti: Sadržaj ove internetske stranice isključivo je odgovornost HEP Toplinarstva d.o.o. i ne odražava nužno gledište Europske unije
    </div>

  </footer>

</div>
