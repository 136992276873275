import { Injectable } from '@angular/core';
import { FLContent } from 'ng-flamelink';
import { from } from 'rxjs';
import { AboutPage } from '../../models/about.page.model';
import { BlogPage } from '../../models/blog.page.model';
import { ContactPage } from '../../models/contact.page.model';
import { GalleryPage } from '../../models/gallery.page.model';
import { HomePage } from '../../models/home.page.model';
import { LinksPage } from '../../models/links.page.model';
import { Page } from '../../models/page.model';
import { HelpersService } from '../helpers/helpers.service';

@Injectable({
  providedIn: 'root'
})
export class PagesContentService {

  constructor(
    private content: FLContent,
    private helpers: HelpersService,
  ) { }

  public async getHome(): Promise<HomePage> {

    const home = (await this._getPage('home')) as HomePage;
    return home;
  }

  public async getAbout(): Promise<AboutPage> {

    const about = (await this._getPage('about', ['numbers', 'area'])) as AboutPage;
    return about;
  }

  public async getBlog(): Promise<BlogPage> {

    const blog = (await this._getPage('blog')) as BlogPage;
    return blog;
  }

  public async getLinks(): Promise<LinksPage> {

    const links = (await this._getPage('links', ['links'])) as LinksPage
    const _links = []
    if (links?.links?.length) {
      for (const link of links.links) {
        _links.push({
          title: link.title,
          url: link.url,
          image: link.image && link.image[0] && this.helpers.proxyImages(link.image[0].url)
        })
      }
    }
    links.links = _links
    return links;
  }

  public async getGallery(): Promise<GalleryPage> {

    const gallery = (await this._getPage('gallery', ['gallery']) as GalleryPage)
    return gallery
  }

  public async getContact(): Promise<ContactPage> {

    const contact = (await this._getPage('contact', ['links'])) as ContactPage;
    return contact;
  }

  /**
   * Get page data by key
   * @param key is page identificator
   */
   private async _getPage(key: string, additionalContentFields: string[] = []): Promise<Page> {
    const page$ = this.helpers.safeUseScullyTransferState(`page_${key}`, from(this.getPageDocument(key)));

    return new Promise((resolve) => {
      const sub = page$.subscribe((page: Page) => {
        console.log('getPage', key, page);

        // NOTE: without filtering Flamelink document
        // BAD: images are not poxied
        // GOOD: other properties can be access from the outside
        // return page;

        // NOTE: with filtering & transforming data from Flamelink document
        const data = {
          status: (page as any)?._fl_meta_?.status,
          title: page.title,
          content: this.helpers.proxyImages(page.content),
        };

        // about page has fields: numbers and area
        additionalContentFields.forEach(contentField => {
          if (typeof page[contentField] === 'string' || page[contentField] instanceof String) {
            data[contentField] = this.helpers.proxyImages(page[contentField]);
          } else {
            data[contentField] = page[contentField]
          }
        });

        // NOTE: take one value and unsubscribe
        setTimeout(() => {
          sub.unsubscribe();
          this.helpers.hideSplashScreen();
        });

        resolve(data);
      });
    });
  }

  /**
   * Get page document from Flamelink
   * @param key is schema key
   */
  private getPageDocument<T>(key: string): Promise<T> {
    return this.content.get({
      schemaKey: key,
      populate: [
        // LinksPage
        {
          field: 'links',
          subFields: [
            {
              field: 'image'
            }
          ]
        },
        // GalleryPage
        {
          field: 'gallery',
          subFields: [
            {
              field: 'image'
            }
          ]
        },
      ]
    });
  }

}
