<div class="home-page-wrapper prose lg:prose-lg max-w-none prose-blue" >

  <cms-content-status [status]="status"></cms-content-status>

  <div class="flex flex-wrap -mt-6 pb-2">

    <gallery class="gallery"
               #gallery
               *ngIf="galleryItems?.length"
               [items]="galleryItems"
               thumbPosition="left"
               [counter]="false"
               [imageSize]="'contain'"
               [nav]="false"
               [dots]="false"
               [autoPlay]="true"
               [slidingDirection]="'horizontal'"
               [playerInterval]="5000"
               (mouseenter)="gallery.stop()"
               (mouseleave)="gallery.play()"
               [thumb]="false"
               [thumbTemplate]="thumbTemplate"
               [itemTemplate]="itemTemplate"></gallery>

  </div>

  <div class="container mx-auto px-4 max-w-6xl" >

    <div class="-mt-8 -mb-4 border-b border-gray-200">
      <h2>{{ title }}</h2>
    </div>

    <div class="flex flex-wrap overflow-hidden pb-2">

      <!-- <div class="w-full overflow-hidden md:w-2/3 md:pr-4">
        <div class="content lg:prose-lg" >
          <markdown [data]="markdownContent" (ready)="onReadyMarkdownContent()"></markdown>

          <a *ngIf="markdownContent" routerLink="/informacije-o-projektu" >pročitaj više...</a>
        </div>
      </div>

      <div *ngIf="title" class="w-full overflow-hidden flex flex-col justify-center md:mt-10 px-4 border md:border-0 md:border-l border-gray-200 md:w-1/3">
        <img class="w-full max-h-96" src="https://ik.imagekit.io/vrelovodosijek/vrelovod-osijek-icon-vector-with-heading.svg">
      </div> -->

      <div class="w-full overflow-hidden">
        <div class="content lg:prose-lg" >
          <markdown [data]="markdownContent" (ready)="onReadyMarkdownContent()"></markdown>

          <a *ngIf="markdownContent" routerLink="/informacije-o-projektu" >pročitaj više...</a>
        </div>
      </div>

    </div>
  </div>

  <!-- <div class="flex flex-wrap flex-row justify-center overflow-hidden" >

    <ng-container *ngFor="let image of bottomImageGrid" >
      <img [src]="image" style="max-width: 512px; width: 33.33vw;" >
    </ng-container>

  </div> -->

  <!-- <div class="container mx-auto px-4 pb-16" *ngIf="title" >

    <div class="-mt-10 -mb-4">
      <div class="-mt-8 mb-4 border-b border-gray-200">
        <h2 style="margin-bottom: 1rem;">Novosti i događaji</h2>
      </div>

      <div class="content lg:prose-lg" >
        <p>
          Detaljne informacije o tijeku napretka projekta možete saznati kroz objavljene novosti.
        </p>

        <a *ngIf="markdownContent" routerLink="/novosti-i-dogadaji" >pročitaj više...</a>
      </div>
    </div>

  </div> -->

  <!-- <div class="container mx-auto px-4 pb-16" *ngIf="title" >

    <div class="-mt-10 -mb-4">
      <div class="-mt-8 mb-4 border-b border-gray-200">
        <h2 style="margin-bottom: 1rem;">Kontakt</h2>
      </div>

      <div class="content lg:prose-lg" >
        <p>
          Besplatni telefon: <a href="tel:08001003" target="_blank">0800 1003</a>
        </p>

        <p>
          e-mail: <a href="mailto:toplinarstvo.osijek@hep.hr" target="_blank">toplinarstvo.osijek@hep.hr</a>
        </p>

        <a *ngIf="markdownContent" routerLink="/kontakt" >više...</a>
      </div>
    </div>

  </div> -->

</div>

