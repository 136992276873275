// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyBAfttEIN1nArYgoLkwnHfxozESyWUH-RQ",
    authDomain: "vrelovod-osijek.firebaseapp.com",
    projectId: "vrelovod-osijek",
    storageBucket: "vrelovod-osijek.appspot.com",
    messagingSenderId: "117406432767",
    appId: "1:117406432767:web:f04bfb044c0855b7a07bca",
    measurementId: "G-4Q9BK6PV39"
  },
  // NOTE: implicit STATIC app are at port 1668 and hostname endsWith .netlify.app
  static: {
    hosts: [
      'vrelovod-osijek.com.hr' // @Netlify
    ]
  },
  // NOTE: implicit DYNAMIC app is at ports 4200 and 1864 and hostname endsWith .web.app
  dynamic: {
    hosts: [
      // NOTE: explicit hostname endsWith .web.app is redundant but only defined as placeholder to be as example
      'vrelovod-osijek.web.app' // @Firebase Hosting
    ]
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
