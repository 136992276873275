import { Component, OnInit } from '@angular/core';
import { IdleMonitorService } from '@scullyio/ng-lib';

@Component({
  selector: 'app-media-page',
  templateUrl: './media-page.component.html',
  styleUrls: ['./media-page.component.scss']
})
export class MediaPageComponent implements OnInit {

  constructor(
    private ims: IdleMonitorService,
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.ims.fireManualMyAppReadyEvent()
    })
  }

  public isLogosVisible = false
  public isForMediaVisible = false
  public isPublicationsVisible = false

  public logos = [
    {
      title: 'RGB colors',
      svg: 'https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FLogotip-%20Zamjena%20spojnog%20vrelovoda%20Osijek%20-%20RGB%20colors.svg?alt=media&token=6a0d6bc1-4e71-4bb5-b75c-fb4d3595e582',
      pdf: 'https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FLogotip-%20Zamjena%20spojnog%20vrelovoda%20Osijek%20-%20RGB%20colors.pdf?alt=media&token=81cad172-b1f1-4bea-ae67-5491674d39fe',
      png: 'https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FLogotip-%20Zamjena%20spojnog%20vrelovoda%20Osijek%20-%20RGB%20colors.png?alt=media&token=637acb4f-8d0d-4224-a7a8-25bde52a87d5',
    },

    {
      title: 'CMYK colors',
      svg: 'https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FLogotip-Zamjena%20spojnog%20vrelovoda%20Osijek-%20CMYK%20colors.svg?alt=media&token=f06ff58d-3939-4164-bb25-578031d55d87',
      pdf: 'https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FLogotip-Zamjena%20spojnog%20vrelovoda%20Osijek-%20CMYK%20colors.pdf?alt=media&token=1e5709ea-a7c7-4738-b4fb-681d27619556',
      png: 'https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FLogotip-Zamjena%20spojnog%20vrelovoda%20Osijek-%20CMYK%20colors.png?alt=media&token=b430fbc2-476d-4d76-a584-f987fbc50484',
    },

    {
      title: 'PLAVA BOJA POZITIV',
      svg: 'https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FLogotip-%20PLAVA%20BOJA%20POZITIV.svg?alt=media&token=30e5d0cc-1af7-49ff-841f-cf419d36cfa6',
      pdf: 'https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FLogotip-%20PLAVA%20BOJA%20POZITIV.pdf?alt=media&token=a41a1ac6-1053-4137-bdfc-935502a890ed',
    },

    {
      title: 'CRVENA BOJA POZITIV',
      svg: 'https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FLogotip-%20CRVENA%20BOJA%20POZITIV.svg?alt=media&token=0af79070-787f-407a-bbd4-b6db79a1318b',
      pdf: 'https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FLogotip-%20CRVENA%20BOJA%20POZITIV.pdf?alt=media&token=90e71490-e966-4027-ac7d-769ba2bec8c7',
    },

    {
      title: 'CRNO BIJELI NEGATIV',
      svg: 'https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FLogotip%20crno-bijeli%20negativ.svg?alt=media&token=a7e75f0b-6d6c-4662-bdd9-88dba3b438b1',
      pdf: 'https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FLogotip%20crno-bijeli%20negativ.pdf?alt=media&token=ff045cae-1189-424c-96ce-c5a69bd007c7',
    },


    {
      title: 'CRNO-BIJELI POZITIV - POLUTON - 30%',
      svg: 'https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FLogotip%20-%20CRNO-BIJELI%20POZITIV-%20POLUTON-%2030%25.svg?alt=media&token=4a50e5cf-18d2-473b-864c-dbd78618bd1a',
      pdf: 'https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FLogotip%20-%20CRNO-BIJELI%20POZITIV-%20POLUTON-%2030%25.pdf?alt=media&token=d192da61-e663-4064-86d8-5c5829b296a5',
    },

    {
      title: 'CRNO-BIJELI POZITIV - POLUTON - 60%',
      svg: 'https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FLogotip-CRNO-BIJELI%20POZITIV-%20POLUTON-60%25.svg?alt=media&token=778c78bd-5d2f-4015-86c0-15bce5fb355a',
      pdf: 'https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FLogotip-CRNO-BIJELI%20POZITIV-%20POLUTON-60%25.pdf?alt=media&token=e6f7f459-a95f-4cdd-ac7f-c212d05b50d2',
    },

    {
      title: 'CRNO-BIJELI POZITIV - 1',
      svg: 'https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FLogotip%20crno-bijeli%20pozitiv%201.svg?alt=media&token=b6d8d091-31ab-4a2e-849c-8055ade9b364',
      pdf: 'https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FLogotip%20crno-bijeli%20pozitiv%201.pdf?alt=media&token=fa5ffcd5-fe6c-472d-910c-e6c48d8f1397',
    },

    {
      svg: 'https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FLogotip_HEP-Toplinarstvo_RGB.svg?alt=media&token=ebdc5d10-985f-4dd9-82f1-1e621285010a',
    },
  ]

  public forMedia = [
    {
      title: 'HEP u obnovu toplinske mreže u Osijeku ulaže 78 milijuna kuna',
      internal: '/novosti-i-dogadaji/potpisivanje-ugovora',
    }
  ]

  public publications = [
    {
      title: 'Brošura projekta',
      external: 'https://firebasestorage.googleapis.com/v0/b/vrelovod-osijek.appspot.com/o/flamelink%2Fmedia%2FZamjena%20spojnog%20vrelovoda%20od%20TE-TO%20Osijek%20do%20TO%20Osijek%20-%20Brosura.pdf?alt=media&token=da16851d-8651-4f24-9b7f-3763d3f49381',
    }
  ]

}
